<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button icon-only @click="back">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Forgot password</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-padding ion-padding-bottom" :fullscreen="true" :scroll-y="false">
      <div class="a-auth full-height">
        <div class="ion-padding a-auth__content-wrapper">
          <h1>Forgot password?</h1>
          <ion-text color="medium" class="ion-margin-bottom">Send me an email reset link</ion-text>

          <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.email)">
            <ion-label position="floating">E-mail</ion-label>
            <ion-input
              v-model="email"
              class="ion-padding-vertical"
              name="email"
              type="email"
              @ionBlur="onBlur(v.email)"
            ></ion-input>
          </ion-item>

          <div v-if="isLoading" class="load-wrap ion-text-center">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p>Loading...</p>
          </div>
          <ion-button v-else :disabled="isFormInvalid" class="--default a-auth__button ion-margin-vertical" @click="reset">Reset my password</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import { arrowBack } from 'ionicons/icons'
  import useVuelidate from '@vuelidate/core'
  import { required, email } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'

  export default {

    data() {
      return {
        email: '',
        arrowBack,
        v: useVuelidate()
      }
    },

    validations: {
      email: { required, email }
    },

    computed: {
      isFormInvalid() {
        return this.v.email.$invalid
      },
      ...mapGetters('wAuth', ['isLoading']),
    },

    methods: {
      back() {
        this.$router.go(-1)
      },

      async reset() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        try {
          await this.$store.dispatch('wAuth/forgotPassword', { email: this.email })
          presentToast('We sent you an email with instructions.', 'success')
        } catch (error) {
          console.error(error.response)
          presentToast(error.response.data.error, 'danger')
        }
      },

      valid(input) {
        return input.$dirty && input.$invalid
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      onBlur(element) {
        element.$touch()
      }
    }
  }
</script>

<style lang="sass" scoped>
ion-header, ion-toolbar
  --background: transparent
</style>
