
  import { arrowBack } from 'ionicons/icons'
  import useVuelidate from '@vuelidate/core'
  import { required, email } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'

  export default {

    data() {
      return {
        email: '',
        arrowBack,
        v: useVuelidate()
      }
    },

    validations: {
      email: { required, email }
    },

    computed: {
      isFormInvalid() {
        return this.v.email.$invalid
      },
      ...mapGetters('wAuth', ['isLoading']),
    },

    methods: {
      back() {
        this.$router.go(-1)
      },

      async reset() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        try {
          await this.$store.dispatch('wAuth/forgotPassword', { email: this.email })
          presentToast('We sent you an email with instructions.', 'success')
        } catch (error) {
          console.error(error.response)
          presentToast(error.response.data.error, 'danger')
        }
      },

      valid(input) {
        return input.$dirty && input.$invalid
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      onBlur(element) {
        element.$touch()
      }
    }
  }
